import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '@/layout'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home')
      },
      {
        path: '/introduce',
        name: 'introduce',
        component: () => import('@/views/home')
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('@/views/home')
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('@/views/home')
      },
      {
        path: '/provide',
        name: 'provide',
        component: () => import('@/views/home')
      }
      // {
      //   path: '/supports',
      //   name: 'supports',
      //   component: () => import('@/views/home')
      // }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
