<template>
  <div>
    <!-- 一、首部 -->
    <div id="header" :class="navBarFixed == true ? 'navBarWrap' :''">
      <div class="open_list" @click="dialog = true">
        <img src="../../../public/img/open.png" alt="">
      </div>
      <!-- 抽屉部分 -->
      <el-drawer
        title="杭州柏帝科技有限公司"
        :visible.sync="dialog"
        custom-class="demo-drawer"
        size="50%"
        :append-to-body="true"
      >
        <div class="drawer_main">
          <el-row>
            <el-col :span="24"><div class="grid-content" @click="dashed()">关于我们</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="24"><div class="grid-content" @click="dashed()">提供服务</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="24"><div class="grid-content" @click="dashed()">产品</div></el-col>
          </el-row>
          <el-row>
            <el-col :span="24"><div class="grid-content" @click="dashed()">联系我们</div></el-col>
          </el-row>
        </div>
      </el-drawer>
      <!-- 2.menu部分 -->
      <div id="header_menu">
        <ul id="menu_list">
          <li class="menu_item"><a href="#introduce">关于我们</a></li>
          <li class="menu_item"><a href="#provide">提供服务</a></li>
          <li class="menu_item"><a href="#product">产品</a></li>
          <li class="menu_item"><a href="#contact">联系我们</a></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<style scoped>
body {
  outline: none;
	width: 100%;
	height: 100%;
	background-color: lightgray;
}

#header {
  background-color: lightgray;
  height: 90px;
  width: 100%;
  position: fixed;
  top: 0px;
  z-index: 1000;
}

/* 吸顶区navBarWrap */
.navBarWrap {
	color: black !important;
	background: #fff !important;
  border-bottom: 1px solid lightgray;
  padding-top: 5px !important;
	padding-bottom: 5px !important;
}
.span_buy:hover{
  cursor: pointer;
}
.drawer_main .el-row{
  margin: 15%;
  text-align: center;
}
.drawer_main .el-row a{
  color: #666666;
  text-decoration: none;
}
.navBarWrap a {
  text-shadow:none !important;
	text-decoration: none;
	color: black !important;
}
/* logo区 */
#header_logo {
	position: absolute;
	top: -12px;
	left: 5%;
  overflow: hidden;
}

#header_logo img {
	width: 88%;
}
#header_logo img:hover {
	cursor: pointer;
}
/* menu区 */
#header_menu {
  height: 100%;
}
#header_menu a{
  color: white ;
  text-decoration: none;
}

#header_menu #menu_list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
	list-style: none;
  height: 100%;
}

#header_menu #menu_list .menu_item {
	width: 100px;
	text-align: center;
	float: left;
  font-size: 18px;
}

#header_menu #menu_list .menu_item hr {
	margin: 0px auto;
	width: 0%;
	margin-top: 2px;
	background-color: #009FF0;
	height: 3px;
	-webkit-box-shadow: none;
	box-shadow: none;
	border: 0;
}

#header_menu #menu_list .menu_item:hover hr {
	-webkit-transition: width 0.15s linear 0s;
	-o-transition: width 0.15s linear 0s;
	transition: width 0.15s linear 0s;
	width: 40%;
}

/* 手机图标 */
.open_list{
  display: none;
}
@media (max-width:1030px){
  #header {
    background-color: lightgray;
    height: 45px;
    width: 100%;
    position: fixed;
    top: 0px;
    z-index: 1000;
  }
  #header_logo img{
    width: 50%;
  }
  #header_menu{
    display: none;
  }
  .navBarWrap{
    min-height: 25px;
  }
  .open_list{
    display: block;
    position:absolute;
    right: 10px;
    top:8px;
    height: 30px;
    width: 30px;
  }
  .open_list img{
    max-height: 100%;
    max-width: 100%;
  }
}
</style>
<script>
export default {
  name: 'Layout',
  data() {
    return {
      dialog: false,
      navBarFixed: false,
      pathName: ''
    }
  },
  watch: {
    $route(to, from) {
      console.log('--->', to.path)
      this.pathName = to.path
    }
  },
  created() {
    this.pathName = this.$route.path
    console.log(this.pathName)
  },
  mounted() {
    window.addEventListener('scroll', this.watchScroll)
  },
  methods: {
    cancelForm() {
      this.loading = false
      this.dialog = false
      clearTimeout(this.timer)
    },
    // 点击头部logo跳转到首页
    logoUrl() {
      this.$router.push('/home')
    },
    // 控制滚动条头部吸顶菜单
    watchScroll() {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 20) {
        this.navBarFixed = true
      } else {
        this.navBarFixed = false
      }
    },
    dashed() {
      this.dialog = false
    }
  }

}
</script>

