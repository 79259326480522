<template>
  <div>
    <web-header />
    <router-view :key="key" />
    <web-footer />
  </div>
</template>

<script>
import WebHeader from './components/header.vue'
import WebFooter from './components/footer.vue'
export default {
  name: 'Layout',
  components: {
    WebHeader, WebFooter
  },
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style>
*{
	margin: 0px;
	padding:0px;
  font-family:'SourceHanSansCN-Medium';
}
</style>
