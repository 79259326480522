<template>
  <div id="footer">
    <!-- <a name="contact" /> -->
    <div id="foot_main">
      <a id="contact" />
      <div class="copy_left">
        <div class="copy_box">
          <p class="foot_name">杭州柏帝科技有限公司</p>
          <p>地址:浙江省杭州市临平区临平街道望梅路619号7幢305室</p>
          <p>电话：+86 15821942696</p>
          <p>邮箱：eric_bei19910925@163.com</p>
          <p>邮编：311103</p>
        </div>
      </div>
      <div class="copy_right">
        <div class="copy_txt">Copyright © 2017-{{ Endtime }} 杭州柏帝科技有限公司 版权所有</div>
      </div>
    </div>
    <!-- 回顶按钮 -->
    <div @click="toTop()">
      <div v-if="topFixed==true" class="back_top" />
    </div>
  </div>
</template>

<style scoped>
#footer {
  background-color: whitesmoke;
  height: 150px;
  width: 100%;
  margin: 0px auto;
  color: #666666;
  padding: 10px 0px;
  /* position: absolute;
  bottom: 0px; */
}
#foot_main {
  padding:0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-around;
  -ms-flex-pack: space-around;
  justify-content: space-around;
  align-items: center;
  /* position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px; */
}
.copy_box {
  height: 100px;
}
.copy_box img {
  height: 100%;
}
.copy_txt {
  height: 30px;
  line-height: 30px;
}
.foot_name {
  margin-bottom: 1vh;
  font-size: 1.8em;
  font-weight: bold;
  font-style: italic;
  color: #666666;
}
/* 回顶按钮 */
.back_top {
  z-index: 2000;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 15px;
  position: fixed;
  right: 3vh;
  bottom: 3vh;
  height: 50px;
  width: 50px;
  background-image: url('../../../public/img/top.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-animation: move 0.1s linear 0s;
  animation: move 0.1s linear 0s;
}
@-webkit-keyframes move {
  from {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  to {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
@keyframes move {
  from {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
  }
  to {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}
span a {
  color: #666666;
  text-decoration: none;
}
@media (max-width: 660px) {
  #foot_main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .foot_name {
    font-size: 1.2em;
  }
  span {
    display: block;
  }
  .copy_box img {
    width: 30%;
  }
  .copy_box,
  .copy_txt {
    font-size: 0.7em;
    height: auto;
  }
  .back_top {
    border-radius: 10px;
    right: 15px;
    bottom: 20px;
    height: 40px;
    width: 40px;
  }
  span img {
    height: 15px;
    width: 15px;
  }
}
</style>
<script>
export default {
  name: 'Layout',
  data() {
    return {
      topFixed: false,
      dialogVisible: false,
      Endtime: new Date().getFullYear()
    }
  },
  mounted() {
    window.addEventListener('scroll', this.watchScroll)
  },
  methods: {
    // 控制底部回到顶部的按钮
    watchScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      if (scrollTop > 200) {
        this.topFixed = true
      } else {
        this.topFixed = false
      }
    },
    toTop() {
      var timer = setInterval(function () {
        var scTop =
          document.documentElement.scrollTop || document.body.scrollTop
        var speed = Math.floor(-scTop / 6)
        document.documentElement.scrollTop = document.body.scrollTop =
          scTop + speed
        if (scTop === 0) {
          clearInterval(timer)
        }
      }, 10)
    }
  }
}
</script>
